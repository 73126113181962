import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().Solutions])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './campaign.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import OptiplusCampaignHero from "../../components/sections/optiplusCampaignHero/OptiplusCampaignHero";
// import OptiplusCampaignTopBar from "../../components/sections/optiplusCampaignTopBar/OptiplusCampaignTopBar";
// import OptiplusCampaignBottomBar from "../../components/sections/optiplusCampaignBottomBar/OptiplusCampaignBottomBar";
// import UnlockThePowerContent from "../../components/sections/unlockThePowerContent/UnlockThePowerContent";
// import OptiplusCampaignSection1 from "../../components/sections/optiplusCampaignSection1/OptiplusCampaignSection1";
// import OptiplusCampaignSection2 from "../../components/sections/optiplusCampaignSection2/OptiplusCampaignSection2";
// import OptiplusCampaignOnlineSalesMovie from "../../components/sections/optiplusCampaignOnlineSalesMovie/OptiplusCampaignOnlineSalesMovie";
// import PredefinedWorkflowMovie from "../../components/sections/PredefinedWorkflowMovie/PredefinedWorkflowMovie";
// import Images3 from "../../components/sections/images3/Images3";
// import OptiplusCampaignPricing from "../../components/shared/optiplusPricing/OptiplusCampaignPricing";
// import { getRoutes } from '../../components/shared/routes';

// const CampaingPage = () => {
//   const { t } = useTranslation();
//   const pageTitle = `${getRoutes().Campaign.pageTitle} | ${getRoutes().OptiwaiPlus.pageTitle}`;


//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Campaign landing page'}/>

//       <div className={css.wrapper}>

//         <div className={css.OptiplusCampaignTopBar}>
//             <OptiplusCampaignTopBar />
//         </div>

//         <div className={css.OptiplusCampaignHero}>
//             <OptiplusCampaignHero />
//         </div>

//         <div className={css.OptiplusCampaignSection1}>
//             <OptiplusCampaignSection1 />
//         </div>

//         <div className={css.OptiplusPricing}>
//             <OptiplusCampaignPricing />
//         </div>

//         <div className={css.OptiplusCampaignSection2}>
//             <OptiplusCampaignSection2 />
//         </div>

//         <div className={css.UnlockThePowerContent}>
//             <UnlockThePowerContent />
//         </div>

//         <div className={css.OptiplusCampaignOnlineSalesMovie}>
//             <OptiplusCampaignOnlineSalesMovie />
//         </div>

//         <div className={css.Images3}>
//             <Images3 />
//         </div>

//         <div className={css.PredefinedWorkflowMovie}>
//             <PredefinedWorkflowMovie />
//         </div>

//         <div className={css.OptiplusCampaignBottomBar}>
//             <OptiplusCampaignBottomBar />
//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default CampaingPage;
